import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';

import SEO from 'components/SEO';
import Section from 'components/Section';
import useSiteMetadata from 'hooks/useSiteMetadata';

export default function PrivacyPage() {
  const { t } = useTranslation();

  const site = useSiteMetadata();
  const title = t('common:privacy_policy');

  return (
    <>
      <SEO
        title={`${title} - ${site.title}`}
        description={t('terms:acceptance')}
        keywords={title}
      />

      <Section>
        <div className="max-w-3xl mx-auto">
          <div className="text-base">
            <h1 className="text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              {title}
            </h1>
            <p className="mt-4 text-gray-500">
              {t('common:last_updated', { date: '10/05/2021' })}
            </p>

            <div className="mt-5 prose prose-blue text-gray-500">
              <h3>{t('privacy:summary')}</h3>

              <p>
                <Trans
                  i18nKey="privacy:sum1"
                  values={{ site: site.title }}
                  components={[<span className="font-bold" />]}
                />
              </p>
              <p>{t('privacy:sum2')}</p>
              <p>{t('privacy:sum3')}</p>

              <h3>{t('privacy:collected_info')}</h3>
              <p>
                <Trans
                  i18nKey="privacy:some_collected_info"
                  values={{ site: site.title }}
                  components={[<span className="font-bold" />]}
                />
              </p>
              <ul>
                <li>{t('privacy:ip_address')}</li>
                <li>{t('privacy:browser_type')}</li>
                <li>{t('privacy:req_time')}</li>
                <li>{t('privacy:ref_site')}</li>
                <li>{t('privacy:lang_pref')}</li>
              </ul>
              <p>{t('privacy:temp_info')}</p>

              <h3>{t('privacy:cookies')}</h3>
              <p>{t('privacy:cookies_info')}</p>
              <p>
                <Trans
                  i18nKey="privacy:cookies_analytics"
                  values={{
                    analytics: 'Google Analytics',
                    optout: t('privacy:analytics_addon'),
                  }}
                  components={[
                    <a
                      href="https://tools.google.com/dlpage/gaoptout"
                      target="_blank"
                      rel="nofollow noreferrer noopener"
                    />,
                  ]}
                />
              </p>
              <p>
                <Trans
                  i18nKey="privacy:propellerads"
                  values={{ site: 'PropellerAds' }}
                  components={[
                    <a
                      href="https://propellerads.com/privacy/"
                      target="_blank"
                      rel="nofollow noreferrer noopener"
                    />,
                    <a
                      href="https://propellerads.com/opt-out/"
                      target="_blank"
                      rel="nofollow noreferrer noopener"
                    />,
                  ]}
                />
              </p>

              <h3>{t('privacy:links')}</h3>
              <p>{t('privacy:out_links')}</p>

              <h3>{t('privacy:privacy_changes')}</h3>
              <p>
                <Trans
                  i18nKey="privacy:privacy_change"
                  values={{ site: site.title }}
                  components={[<span className="font-bold" />]}
                />
              </p>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "privacy", "videoproc"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
